import React, { useEffect, useState } from 'react';
import '../css/Spielkarte.css';

export function Spielkarte() {
  const [randomText, setRandomText] = useState({});
  const [voted, setVoted] = useState(false);
  const [votedIDid, setVotedIDid] = useState(undefined);
  const [votedIDidnt, setVotedIDidnt] = useState(undefined);
  const [votedTotal, setVotedTotal] = useState(undefined);

  const [remainingTexts, setRemainingTexts] = useState([]);

  useEffect(() => {
    document.title = 'Ich hab noch nie - Spielkarte';

    // Lade die Texte aus sessionStorage
    const storedTexts = JSON.parse(sessionStorage.getItem('questions'));
    if (storedTexts && storedTexts.length > 0) {
      setRemainingTexts(storedTexts);
      handleRandomize(storedTexts); // Rufe die Funktion auf, um einen zufälligen Text auszuwählen
    }
  }, []);

  const handleRandomize = (texts = remainingTexts) => {
    if (texts.length === 0) {
      setRandomText({});
      return;
    }

    const randomTextIndex = Math.floor(Math.random() * texts.length);
    setRandomText(texts[randomTextIndex]);
    setVoted(false);
    setVotedIDid(undefined);
    setVotedIDidnt(undefined);
    setVotedTotal(undefined);
    const newRemainingTexts = texts.filter(
      (_, index) => index !== randomTextIndex,
    );
    setRemainingTexts(newRemainingTexts);
    sessionStorage.setItem('questions', JSON.stringify(newRemainingTexts));
  };

  function handleVote(iDid) {
    setVoted(true);
    fetch('/api/question/' + randomText.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ iDid: iDid }),
    }).then((response) => response.json()).then((data) => {
      console.log(data);
      setVotedIDid(data.amountOfIHaveAnswers);
      setVotedIDidnt(data.amountOfIHaventAnswers);
      setVotedTotal(data.total);
    });
  }

  return (
    <>
      <div className="container">
        <div className="card">
          <p id="kartentitel">Ich hab noch nie:</p>
          <p id="kartentext">
            {randomText.question ||
              'Leider waren das alle Fragen. Um unsere Datenbank zu erweitern schreib uns gerne weitere unter: ich-hab-noch-nie.de@outlook.de'}
          </p>
          <button className="card-button" onClick={() => handleRandomize()}>
            Next
          </button>
        </div>
        <div></div>
      </div>
      <button className="ButtonVote Have" disabled={voted} onClick={() => handleVote(false)}>Noch
        nie! {votedIDidnt !== undefined ? votedIDidnt : ''}</button>
      <button className="ButtonVote Never" disabled={voted} onClick={() => handleVote(true)}>Schon
        gemacht. {votedIDid !== undefined ? votedIDid : ''}</button>
    </>
  );
}
