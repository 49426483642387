import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Spielkarte } from './Spielkarte';
import { SpielkarteDeeptalk } from './SpielkarteDeeptalk';
import { SpielkarteDisskussion } from './SpielkarteDisskussion';
import { DisskussionFragen } from '../Komponenten/DisskussionFragen';
import { DeeptalkFragen } from '../Komponenten/Deeptalkfragen';
import { Startseite } from './Startseite';
import { NotFound } from '../Komponenten/NotFound';
import { Impressum } from '../Komponenten/Impressum';
import FixedSizeComponent from '../Komponenten/Fixscreen';
import FixedSizeComponentDeeptalk from '../Komponenten/FixscreenDeeptalk';
import FixedSizeComponentDisskussion from '../Komponenten/FixscreenDisskussion';
import { Footer } from '../Komponenten/Footer';
import { Header } from '../Komponenten/Header';
import { Datenschutzerklärung } from '../Komponenten/Datenschutzerklärung';
import { TrinkspielLP } from '../Komponenten/TrinkspielLP';
import { FlunkyballLP } from './Flunkyball';
import { DeeptalkLP } from '../Komponenten/DeeptalkLP';
import { DisskussionLP } from '../Komponenten/DisskussionLP';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export function NHIE() {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedQuestions, setSelectedQuestions] = useState({
    fragen18: true,
    fragenlustig: true,
    fragenpeinlich: true,
  });
  const [texts, setTexts] = useState([]);
  const [embarrassingQuestions, setEmbarrassingQuestions] = useState();
  const [nsfwQuestions, setNsfwQuestions] = useState();
  const [funnyQuestions, setFunnyQuestions] = useState();

  useEffect(() => {
    fetch('/api/questions/embarrassing')
    .then((response) => response.json())
    .then((data) => setEmbarrassingQuestions(data));
    fetch('/api/questions/nsfw')
    .then((response) => response.json())
    .then((data) => setNsfwQuestions(data));
    fetch('/api/questions/funny')
    .then((response) => response.json())
    .then((data) => setFunnyQuestions(data));
  }, []);


  useEffect(() => {
    if (location.pathname === '/') {
      document.title =
        'Ich hab noch nie, JETZT SPIELEN.🥳 | Lustig | Fragen | 18+';
      // Reset the selected questions when navigating back to NHIE component
      setSelectedQuestions({
        fragen18: true,
        fragenlustig: true,
        fragenpeinlich: true,
      });
    }
  }, [location.pathname]);

  function handleStartGame() {
    const newTexts = [];
    if (selectedQuestions.fragen18) newTexts.push(...nsfwQuestions);
    if (selectedQuestions.fragenlustig) newTexts.push(...funnyQuestions);
    if (selectedQuestions.fragenpeinlich)
      newTexts.push(...embarrassingQuestions);
    setTexts(newTexts);
    sessionStorage.setItem('questions', JSON.stringify(newTexts));
    navigate('/Spielkarte');
  }

  function handleStartDeeptalk() {
    const newTexts = [];
    newTexts.push(...DeeptalkFragen().texts);
    setTexts(newTexts);
    sessionStorage.setItem('texts', JSON.stringify(newTexts));
    navigate('/DeeptalkSpielkarte');
  }

  function handleStartDisskussion() {
    const newTexts = [];
    newTexts.push(...DisskussionFragen().texts);
    setTexts(newTexts);
    sessionStorage.setItem('texts', JSON.stringify(newTexts));
    navigate('/DisskussionsFragenSpielkarte');
  }

  function handleImpressum() {
    navigate('/Impressum');
  }

  function handleHome() {
    navigate('/');
  }

  function handleDate() {
    navigate('/Datenschutzerklärung');
  }

  function handleTrink() {
    navigate('/Trinkspiele/');
  }

  function handleTrink() {
    navigate('/Flunkyball/');
  }

  function handleDeep() {
    navigate('/Deeptalk/');
  }

  function handleDisk() {
    navigate('/Disskussionsfragen/');
  }

  function handleCheckboxChange(event) {
    const { name, checked } = event.target;
    setSelectedQuestions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HelmetProvider>
                <Helmet>
                  <link rel="canonical" href="https://ich-hab-noch-nie.de" />
                  <meta
                    name="description"
                    content="Ich hab noch nie als App im Browser, mit einer stetig wachsenden Sammlung der besten Fragen und einer Spielanleitung. Jetzt Spielen!"
                  />
                </Helmet>
                <Header
                  onImp={handleImpressum}
                  onHome={handleHome}
                  onDate={handleDate}
                  onTrink={handleTrink}
                  onDeep={handleDeep}
                  onDisk={handleDisk}
                />
                <FixedSizeComponent
                  onNavigate={handleStartGame}
                  selectedQuestions={selectedQuestions}
                  onCheckboxChange={handleCheckboxChange}
                />
                <Startseite />
                <Footer
                  onImp={handleImpressum}
                  onHome={handleHome}
                  onDate={handleDate}
                />
              </HelmetProvider>
            </>
          }
        />
        <Route
          path="/Spielkarte"
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <Spielkarte />
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="/Deeptalk"
          status={404}
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <FixedSizeComponentDeeptalk onNavigate={handleStartDeeptalk} />
              <DeeptalkLP></DeeptalkLP>
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="/DeeptalkSpielkarte"
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <SpielkarteDeeptalk texts={texts} title="Du bist im Spiel" />
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="/Diskussionsfragen"
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <FixedSizeComponentDisskussion
                onNavigate={handleStartDisskussion}
              />
              <DisskussionLP></DisskussionLP>
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="/DiskussionsFragenSpielkarte"
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <SpielkarteDisskussion texts={texts} title="Du bist im Spiel" />
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="/Impressum"
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <Impressum />
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="/Datenschutzerklärung"
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <Datenschutzerklärung />
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="/Trinkspiele"
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <TrinkspielLP />
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="/Flunkyball"
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <FlunkyballLP />
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
        <Route
          path="*"
          status={404}
          element={
            <>
              <Header
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
                onTrink={handleTrink}
                onDeep={handleDeep}
                onDisk={handleDisk}
              />
              <NotFound />{' '}
              <Footer
                onImp={handleImpressum}
                onHome={handleHome}
                onDate={handleDate}
              />
            </>
          }
        />
      </Routes>
    </>
  );
}
